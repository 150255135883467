@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
//@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;


@include motion-ui-transitions;
@include motion-ui-animations;

//@include foundation-everything($prototype: true);
@import 'util/mixins';
@include horizontal-center;
@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);

html {
    font-size: 16px;

}

body {
    margin: 0px;
    font-size: 0.9rem;
    color: #333;
    font-family: "Noto Sans Japanese", "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS P Gothic", Osaka, Verdana, Helvetica, sans-serif;
}

@media screen and (min-width:960px) {
    body {
        background-image: url(/assets/templates/ubc/images/bg.gif);
        background-color: #FFF;
        background-repeat: repeat-x;
    }
}

#wrapper {

    width: 100%;
    margin: 0 auto;
    position: relative;
}

/***---------- header ----------***/

#header {
    width: 100%;
    position: relative;
    height: 60px;
    margin: 0 auto;
    padding: 5px 0 0 0;
    background-color: #FFF;
    #logo {
        height: 50px;
        line-height: 50px;
        padding-right: 60px;
        padding-left: 10px;
        img {
            max-height: 50px;
        }
    }
}

.menuBtn {
    width: 60px;
    height: 60px;
    position: fixed;
    top: 0px;
    right: 0;
    color: #fff;
    background: $global;
    padding: 10px;
    z-index: 20;
    transition: background-color 0.4s;
    p {
        margin: 0;
        taxt-align: center;
    }
}

#header_list {
    display: none;

}

#site-id {
    padding: 0px 0 5px 15px;
    a img {
        vertical-align: bottom;
    }
    a:link,
    #site-id a:visited {
        color: #333;
        text-decoration: none;
    }
    a:hover,
    a:focus,
    a:active {
        color: #FF6600;
        text-decoration: underline;
    }
}

@media screen and (min-width:768px) {
    #header {
        height: 160px;
        #logo {
            heifgt: 65px;
            line-height: 65px;
            img {
                max-height: 65px;
                padding-left: 10px;
            }
        }
    }
    #header_list {
        display: none;

    }
    .menuBtn {
        display: none;
    }

}

@media screen and (min-width:960px) {
    #header {
        background-image: url(/assets/templates/ubc/images/head.png);
        background-repeat: no-repeat;
        background-position: right 0;
        height: 170px;
        #logo {
            height: 100px;
            line-height: 90px;
            img {
                max-height: 100px;
            }
        }
        #tel_img {
            height: 100px;
            line-height: 90px;
            padding-top: 10px;
        }
    }
    #header_list {
        display: block;
        position: absolute;
        right: 20px;
        top: -2px;
        text-align: right;
        li {

            display: inline;
            padding-right: 7px;
            padding-left: 13px;
            background-image: url(/assets/templates/ubc/images/li.gif);
            background-repeat: no-repeat;
            background-position: 3px center;
            font-size: 10px;
        }
        a {
            text-decoration: none;
            color: #6C6759;
        }
        a:hover {
            text-decoration: none;
            color: #660000;
        }

    }
}

/* #search-box */

#search-box {
    display: none;
}

@media screen and (min-width:960px) {
    #search-box {
        width: 100%;
        height: 75px;
        padding: 25px 0 10px 0;
        display: inline-block;
        dt {
            line-height: 1;
            padding: 0 10px 2px 0;
            margin-left: 0;
            font-size: 14px;

            width: 80px;
            img {
                vertical-align: top;
            }
        }
        dd {
            display: inline-block;
            position: relative;
            width: 260px;
            height: 40px;
            fieldset {
                border: none;
            }
        }
        #ajaxSearch_input {
            width: 170px;
            height: 35px;
            float: left;
            color: #ccc;
        }
        #ajaxSearch_submit {
            width: 60px;
            height: 30px;
            float: right;

        }
        #ajaxSearch_intro {
            display: none;
        }

    }
}

form#ajaxSearch_form fieldset {
    border: none;
}

/**---------- #global-navi ----------**/

#global-nav {
    display: none;
}

@media screen and (min-width:768px) {
    #global-nav {
        display: block;
        height: 50px;
        width: 100%;
        margin: 0 auto;

        .top-bar {
            height: 50px;
            ul {
                background: $global;
                li.active ul li a {
                    border-top: none;
                }
                li {
                    display: inline;
                    a {
                        line-height: 16px;
                        display: block;
                        height: 50px;
                        padding: 8px 25px;
                        font-size: .9rem;
                        color: #FFFFFF;
                        text-decoration: none;
                        border-right: 1px solid #D5C4C4;
                        text-align: center;
                        background: $global;
                        span {
                            line-height: 10px;
                            font-size: .65rem;
                            color: $yellow;
                        }
                    }
                    a:hover {
                        background: #FFE4E1;
                        color: #432323;
                        span {
                            color: #BF5B5B;
                        }
                    }
                    ul.menu {
                        li {
                            height: 30px;
                            line-height: 1.5;
                            a {
                                text-align: left;
                                font-size: .8rem;
                                padding: 10px 10px;

                            }
                        }
                    }
                }

                li.active a {
                    border-top: solid 3px $orange;
                    padding: 5px 25px 8px;

                }
            }
        }

    }

}

/*  キャンペーン用バナー */

#campaign_sp {
    margin: 15px auto;
}

.ca_ba {
    margin-top: 15px;
}

@media screen and (min-width:960px) {
    #campaign_sp {
        display: none;
    }
}

/*  slidemenu */

.off-canvas {
    padding-bottom: 60px;
}

#slidemenu li a {
    color: #fff;
}

#slidemenu li a::after {
    border-color: #fff transparent transparent;
}

.off-canvas ul.banner {
    margin: 30px auto 10px;
    li {
        list-style: none;
        color: #fff;
        text-align: center;
        margin-bottom: 15px;
        a img {
            width: 200px;
            margin: 10px auto;
            display: table;
        }
    }
}

.off-canvas ul.banner2 {
    margin: 30px auto 10px;
    padding-bottom: 60px;
    li {
        list-style: none;
        color: #fff;
        text-align: center;
        margin-bottom: 15px;
    }
}

.submenu-toggle::after {
    border-color: #fff transparent transparent;
}

#header .title-bar {
    background: $global;
    width: 100%;
    color: #fff;
    .menu-icon {
        margin-left: 20px;

    }
}

#header .title-bar-left {
    width: 100%;
}

.title-bar-title {
    color: $yellow;
}

@media screen and (min-width:960px) {
    .title-bar {
        display: none;
    }
}

/***---------- Page-top ----------***/

#page-top {
    position: fixed;
    margin: 0;
    bottom: 60px;
    right: 0px;
    height: 45px;
    width: 45px;
    background: #2a1d10;
    a {
        display: block;
    }
    a:after {
        font-family: 'FontAwesome';
        content: "\f077";
        position: fixed;
        bottom: 70px;
        right: 12px;
        color: #fff;
    }
    a:hover {
        text-decoration: none;
        background: #999;
    }
}

/***---------- #side-btn ----------***/

#side_btn {
    display: none;
    position: fixed;
    bottom: 250px;
    right: 0;
    width: 39px;
}

#side_btn img {
    margin-top: 10px;
}

/***---------- #bottom_menu ----------***/

ul#bottom_menu {
    position: fixed;
    margin: 0;
    padding: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    text-align: center;
    list-style: none;
    z-index: 50;
    li {
        height: 60px;
        font-size: 0.85rem;
    }
    li.reserve {
        width: 37%;
        float: left;
        display: table-cell;
        padding: 4px;
        background-color: $red;
        a {
            display: block;
            color: #fff;
            span {
                font-size: 1.1rem;
                height: 41px;
                width: auto;
            }
        }
    }
    li.mail {
        width: 30%;
        float: left;
        display: table-cell;
        padding: 4px;
        background-color: $yellow;
        a {
            color: #fff;
            display: block;
            span {
                font-size: 1.1rem;
                height: 41px;
                width: auto;
            }
        }
    }
    li.phone {
        width: 33%;
        float: left;
        display: table-cell;
        padding: 4px;
        background-color: $blue;
        a {
            display: block;
            color: #fff;
            span {
                font-size: 1.1rem;
                height: 41px;
                width: auto;
            }
        }
    }
}

@media screen and (min-width:768px) {
    #bottom_menu {
        display: none;
    }
}

/***---------- #contents ----------***/

#contents {
    clear: both;
    margin: 0 auto;
    padding: 0px 10px 10px;
    background: #FFF;
    text-align: left;
    width: 100%;
}

/* #maincontent */

#maincontent {
    margin: 0;
    width: 100%;
    a {
        color: #DB7093;
    }
    a:hover {
        text-decoration: underline;
        color: #FFC0CB;
    }
    ul li {
        list-style: none;
        a {
            padding-left: 5px;
        }
    }

}

@media screen and (min-width:960px) {
    #maincontent {
        margin: 0 5px 0px 10px;
        width: 74%;
    }
}

#maincontent p.topicpath {
    padding-top: 5px;
}

/*  #subcontent, #subcontent2 */

#subcontent {
    padding: 0 15px;
    margin: 30px 10px 10px;
    ul#ddR1 {
        padding: 5px;
        font-size: 0.75rem;
        background-color: $sidemenu;
        li {
            list-style: none;
            a {
                color: #fff;
            }
        }
        li.is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
            border-color: #fff transparent transparent;
        }
    }
    ul.banner {
        max-width: 240px;
        list-style-type: none;
        margin: 20px auto;
        li {
            margin-top: 10px;

        }
        li.tw {
            color: #1B95E0;
            text-align: center;
        }

    }

}

.menu-title {
    margin: 0;
    padding: 12px 15px;
    font-size: 1.1rem;
    width: 100%;
    text-align: center;
    color: $sidemenu;
    background: #fff;
    border: solid 1px $sidemenu;
}


/*----------------------------------------------*/

/* Footer */

/*----------------------------------------------*/

#footer {
    width: 100%;
    margin: 0;
    padding: 0;
    background: none repeat scroll 0 0 #2a1d10;
    clear: both;
    border-top: 0.2rem solid #ccc;
}

#footer.boxed {
    /*box-shadow: inset 0 0 0 3px #f5f5f5;*/
}

.footer-wrap {
    width: 100%;
    padding: 28px 1% 10px;
    margin: 0 auto;
    height: auto;
    background: none repeat scroll 0 0 #2a1d10;
    background: url("/assets/templates/ubc/images/glow-bottom-brown.png") no-repeat scroll center bottom #2a1d10;
}

#footer .title {
    padding-bottom: 3px;
    padding-left: 3px;
    margin: 5px 0 5px;
    color: #cecece;
    border-bottom: 1px dashed #777;
    display: block;
    text-align: left;
    font-size: 1rem;
}

#footer a {
    text-decoration: none;
    color: #C9BE9C;
}

#footer a:hover {
    text-decoration: none;
    color: #ddd;
}

#footer p {
    font-size: 0.8rem;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    color: #C9BE9C;
    line-height: 1.5em;
    margin-bottom: 10px;
}

.post-footer a:hover {
    color: #aaa !important;
}

.post-footer-wrap {
    padding: 0 0 50px 0;
    margin: 0;
    width: 100%;
    color: #7b7b7b;
    text-align: center;
}

#foot1 {
    margin-top: 5px;

    ul.bullet1 {
        padding-top: 15px;
        display: table;
        margin: 5px auto 10px;
        li {
            background: transparent url(/assets/templates/ubc/images/arrow-gray.png) no-repeat;
            background-position: 3px 10px;
            margin: 0;
            padding: 5px 0 12px 10px;
            list-style-type: none;
            font-size: 0.85rem;
            color: #909090;
        }
    }
}

#foot2 {
    margin-top: 5px;
    ul.bullet2 {
        padding-top: 5px;
        display: table;
        margin: 5px auto;
        li {
            background: transparent url(/assets/templates/ubc/images/bullet.png) no-repeat;
            background-position: 0 13px;
            margin: 0;
            padding: 5px 0 12px 10px;
            list-style-type: none;
            font-size: 0.9rem;
            color: #909090;
        }
    }

    ul.bullet3 {
        padding-top: 15px;
        display: table;
        margin: 5px auto 10px;
        li {
            background: transparent url(/assets/templates/ubc/images/bullet.png) no-repeat;
            background-position: 0 16px;
            padding: 7px 0 12px 10px;
            list-style-type: none;
            font-size: 0.9rem;
            color: #909090;
        }
    }

}

#foot3 {
    table.info {
        margin: 20px auto 0;
        width: 90%;
        th {
            background: #E6DEDE;
            border: solid 1px #ccc;
            font-size: 0.85rem;
        }
        td {
            background: #fff;
            font-size: 0.85rem;
        }
    }
    #foot_map {
        width: 300px;
        margin: 0 auto;
    }

}

ul.bullet4 {
    padding-top: 15px;
    display: table;
    margin: 5px auto 10px;
    li {
        img {
            width: 200px;
        }
    }
}

.foot_banner {
    margin: 5px auto;
    display: table;
    li {
        float: left;
        list-style: none;
        margin: 15px;
        a {

            img {
                width: 240px;
                margin: 10px 0;
            }
        }
    }
}

.post-footer {
    width: 100%;
    margin: 0;
    padding: 0;
    background: url("/assets/templates/ubc/images/bg-header-brown.png") repeat-x scroll center top #271604;
}

.post-footer a {
    text-decoration: none;
    color: #C9BE9C;
    margin-left: 10px;
}

/* .copyright */

#copy {
    font-size: .9rem;
    text-align: center;
    color: #fff;
    font-style: italic;
    margin-top: 0px;
    padding-top: 0;
    padding-bottom: 75px;
    background: #2a1d10;
}

@media screen and (min-width:768px) {
    #copy {
        padding-bottom: 15px;
    }
}

@media screen and (min-width:600px) {
    #footer ul li {
        font-size: 0.8rem;
    }
}

/**----------class ----------**/

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.under {
    text-decoration: underline;
}

.bold {
    font-weight: bold;
}

.red {
    color: $red;
}

.blue {
    color: $blue;
}

.brown {
    color: $global;
}

.fr {
    float: right;
}

.fl {
    float: left;
}

a:hover img {
    opacity: 0.7;
    filter: alpha(opacity=70);
    -ms-filter: "alpha( opacity=70 )";
    zoom: 1
}

#maincontent a.button {
    color: #fff;
}

#maincontent a.button:hover {
    text-decoration: none;
}

/**----------mainvisual ----------**/

#mainvisual {
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
    display: table;

}

/**----------maincontent ----------**/

#maincontent p {
    padding: 0px 8px;
}

#maincontent ul.disc {
    list-style-type: disc;
    li {
        list-style-type: disc;
    }
}

#maincontent .entry {
    padding: 5px;
}

/**----------h1 ----------**/

#maincontent h1 {
    margin-bottom: 15px;
    text-align: left;
    font-weight: bold;
    color: #464646;
    height: 33px;
    background-color: #ffffff;
    background-image: url(/assets/templates/ubc/images/menu_red2.png);
    background-repeat: no-repeat;
    font-size: 1.1rem;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    padding-right: 0px;
    padding-bottom: 5px;
    padding-left: 2em;
    padding-top: 4px;

}

/* h2 */

#maincontent h2 {
    margin: 20px 0 0.8em;
    padding: 0.6em;
    border-left: 7px solid $h2;
    border-bottom: 1px dashed $h2;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.1;

}

/* h3 */

#maincontent h3 {
    position: relative;
    color: white;
    background: #81d0cb;
    line-height: 1.4;
    padding: 0.5em 0.5em 0.5em 1.8em;
    font-size: 1.0rem;
}

#maincontent h3:before {
    /*疑似要素*/
    font-family: FontAwesome;
    content: "\f14a";
    position: absolute;
    left: 0.5em;
    /*左端からのアイコンまでの距離*/
}

/* h4 */

#maincontent h4 {
    line-height: 1.4;
    margin: 0 0 10px 10px;
    padding: 0;
    border: none;
    background: none;
    color: #A52A2A;
    font-size: 0.95rem;
    font-weight: bold;
    font-family: "Noto Sans Japanese", "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS P Gothic", Osaka, Verdana, Helvetica, sans-serif;

}


#maincontent li.item h4 a {
    color: #DC143C;

}

#maincontent .card-divider h4 {
    margin: 0;
    a {
        color: #fff;
        font-family: "Noto Sans Japanese", "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS P Gothic", Osaka, Verdana, Helvetica, sans-serif;

    }
    a:hover {
        color: #ccc;
        text-decoration: none;
    }
}

/* h5 */

#maincontent h5 {
    line-height: 1.4;
    margin: 0 0 8px 10px;
    padding: 1px 0 5px 20px;
    border: none;

    color: #333;
    font-size: 90%;
    font-weight: bold;
}

#maincontent .brown {
    color: #A52A2A;
}

/**---------- topicpath ----------**/

#maincontent p.topicpath {
    line-height: 1.4;
    margin: 0 0 20px 0;
    color: #000;
    font-size: 0.8rem;

    text-align: left;
    letter-spacing: 1px;
}

#maincontent p.topicpath a {
    text-decoration: underline;
    color: #333;
}

#maincontent p.topicpath a:hover {
    color: #999;
}

#maincontent_mail p.topicpath a {
    text-decoration: underline;
    color: #333;
}

#maincontent_mail p.topicpath a:hover {
    color: #999;
}


/**---------- news ----------**/

.news {
    width: 100%;
    height: auto;
    padding: 5px 5px 30px;
    margin: 10px auto 15px;
    h2 {
        font-weight: bold;
        margin-left: 0;
        font-size: 0.8rem
    }
    .news_title {
        padding: 0.3em 0 0.3em 1.5rem;

        a {
            font-size: 0.9rem;
            color: #494949;
        }
        a:before {
            font-family: 'FontAwesome';
            content: "\f101";
        }

    }

    .news_text {

        padding: 0px 0 0px 25px;
        width: 95%;
        text-align: left;
        p {
            font-size: 0.9rem;
            font-weight: normal;
            margin: 0;
        }
        a:hover {

            color: #D3D3D3;
        }
        h3 {
            font-weight: bold;
            color: #FF4500;
            font-size: 0.8em;
            margin-top: 15px;
            margin-left: 0em;
            border: 0;
        }
    }

    .newssection {
        padding: 5px 0;

    }

}

/**---------- muuri ----------**/

#muuri {
    padding: 10px 0px;
    .grid {

        margin: 0 auto;
        position: relative;
        .item {
            width: 150px;
            margin: 10px 2px;
            border: solid 1px #B26A6A;
            background: none;
            display: block;
            position: absolute;
            margin: 5px;
            .card-divider {
                padding: 0.5rem;
                font-size: 0.8rem;
                a {
                    margin: 0px;
                    text-decoration: none;
                    color: #fff;
                    font-size: 0.8rem;
                }
                a:hover {
                    margin: 0px;
                    text-decoration: none;
                    color: #999;
                }
            }
            .card-section {

                margin: 0px;
                font-size: 0.7rem;
                color: #000;
                padding: 5px 0.2rem;
                margin-top: 0px;
                a {
                    margin: 5px 0;
                    line-height: 2em;
                    color: #000;
                }
                a:hover {
                    color: #ccc;
                }

            }
        }
    }
}

@media screen and (min-width:640px) {
    #muuri {
        .grid {

            .item {
                width: 190px;
                margin: 10px 5px;
            }
        }
    }

}

/**---------- Access ----------**/

#gmap {
    width: 98%;
    height: 460px;
    margin: 20px auto;
    padding: 10px 0;
}

table.access {
    border-collapse: collapse;
    width: 98%;
    margin: 10px auto;
    td,
    th {
        display: block;
        width: 100%;
    }
    th {
        background: $pink;
    }

}

@media screen and (min-width:640px) {
    table.access {
        margin: 20px auto;
        width: 80%;
        th,
        td {
            display: table-cell;
        }
        th {

            color: #000;
            font-weight: bold;
            text-align: left;
            font-size: 0.9rem;
            margin: 0;
            padding: 10px 20px 10px 20px;
            width: 30%;
        }
        td {

            width: 65%;
            font-size: 0.8rem;
            padding: 10px;

        }
    }
}

#maincontent dl.access {
    margin-top: 15px;
    dt {
        line-height: 1.4;
        margin: 0 0 8px 0px;
        padding: 2px 0 2px 15px;
        border: none;
        color: #f89174;
        font-size: 1rem;
        font-weight: bold;
        position: relative;
    }
    dt.train:before {
        font-family: FontAwesome;
        content: "\f239";
        padding-right: 5px;
        /*文字との隙間*/
        color: #f89174;
        /*アイコンの色*/
    }
    dt.car:before {
        font-family: FontAwesome;
        content: "\f1b9";
        padding-right: 5px;
        /*文字との隙間*/
        color: #f89174;
        /*アイコンの色*/
    }
}


#maincontent dl.access dd {
    margin: 0 0 0 10px;
}

/**---------- Faq ----------**/

#maincontent .faq dl dt {
    color: $orange;
}

/**---------- Anest ----------**/

.anest {
    text-align: center;

    img {
        width: 70%;
        padding: 15px;
    }
}

/**---------- Table ----------**/

#maincontent #price h3 {
    position: relative;
    padding: 0.25em 0 0.5rem 0;
    color: #333;
    background: none;
    font-size: 1.0rem;
}

#maincontent #price h3:before {
    display: none;
}

#maincontent #price h3:after {
    content: "";
    display: block;
    height: 4px;
    background: -moz-linear-gradient(to right, rgb(230, 90, 90), transparent);
    background: -webkit-linear-gradient(to right, rgb(230, 90, 90), transparent);
    background: linear-gradient(to right, rgb(230, 90, 90), transparent);
}


.table1 {

    margin: 10px 0 40px 0;


    th {
        color: #000;
        font-weight: bold;
        text-align: left;
        background-color: #ECEAE8;
        font-size: 0.9rem;
        margin-right: 20px;
        padding: 10px 20px 10px 20px;

    }
    td {

        font-size: 0.8rem;
        border: 1px solid #eceae8;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }
    .color {
        color: #000;
        text-align: left;
        background-color: #FFE4E1;
        font-size: 0.8rem;
        font-weight: bold;
    }

    .color2 {
        color: #000;
        text-align: left;
        background-color: #FFE4E1;
        font-size: 0.8rem;
        font-weight: bold;
    }
    .color3 {
        color: #000;
        text-align: left;
        background-color: #FCC;
        font-size: 0.8rem;
    }
}


.table2 {
    margin: 10px 0 40px 0;
    th {
        color: #000;
        font-weight: bold;
        text-align: left;
        background-color: #ECEAE8;
        font-size: 0.9rem;
        margin-right: 20px;
        padding: 10px 20px 10px 20px;

    }
    .color2 {
        color: #000;
        text-align: left;
        font-size: 0.8rem;

    }
    .color3 {
        color: #000;
        text-align: left;
        background-color: #FFE4E1;
        font-size: 0.8rem;
    }
}

.table3 {
    margin: 10px 0 40px 0;
    th {
        color: #000;
        font-weight: bold;
        text-align: left;
        background-color: #FFE4E1;
        font-size: 0.9rem;
        border: 1px solid #ccc;
        width: 100%;
        display: block;
    }
    td {
        font-size: 0.8rem;
        border: 1px solid #eceae8;
        width: 100%;
        display: block;
    }
}

@media screen and (min-width:640px) {
    .table3 {
        margin: 10px 0 40px 0;
        display: table;
        th {
            margin-right: 20px;
            padding: 10px 20px 10px 20px;
            display: table-cell;
            width: 60%;
        }
        td {
            padding: 10px;
            display: table-cell;
            width: 38%;
        }
    }
}

/**---------- Job ----------**/

table.job {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 0px 15px;
    font-size: 0.8rem;
}

table.job td {
    width: 100%;
    display: block;
    font-size: 0.9rem;
    padding: 10px 10px 10px 20px;
}

table.job th {
    display: block;
    width: 100%;
    padding: 10px;
    background: #295890;
    vertical-align: middle;
    text-align: left;
    overflow: visible;
    position: relative;
    color: #fff;
    font-weight: normal;
    font-size: .9rem;
}

@media screen and (min-width:768px) {
    table.job th {
        width: 30%;
        display: table-cell;
        padding: 10px;
    }
    table.job td {
        width: 70%;
        display: table-cell;
        padding: 10px 10px 10px 20px;
    }
    table.job th:after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-left-color: #295890;
        border-width: 10px;
        margin-top: -10px;
    }

    /* firefox */
    @-moz-document url-prefix() {
        table.job th::after {
            float: right;
            padding: 0;
            left: 30px;
            top: 10px;
            content: " ";
            height: 0;
            width: 0;
            position: relative;
            pointer-events: none;
            border: 10px solid transparent;
            border-left: #295890 10px solid;
            margin-top: -10px;
        }
    }
}



/**---------- MediaObject ----------**/

.media thumbnail {
    display: inline-block;
    margin: 0 auto;
}

.img-text {
    display: table;
    margin: 5px auto;
    padding: 10px 0px;
    p {
        padding: 10px 5px;
        vertical-align: middle;
        font-size: 0.8rem;
    }

}

/**---------- Form ----------**/

h1.form {
    text-align: left;
    font-weight: bold;
    color: #464646;
    height: 33px;
    background-color: #ffffff;
    background-image: url(/assets/templates/ubc/images/menu_red2.png);
    background-repeat: no-repeat;
    font-size: 1.0rem;
    margin: 0px 0 15px 0;
    padding: 4px 0px 5px 2em;
}

#form h4 {
    line-height: 1.4;
    margin: 0 0 10px 0px;
    padding: 0;
    border: none;
    background: none;
    color: #A52A2A;
    font-size: 0.95rem;
    font-weight: bold;
}

#form h5 {
    line-height: 1.4;
    margin: 0 0 8px 0px;
    padding: 1px 0 5px 0px;
    border: none;

    color: $blue;
    font-size: .85rem;
    font-weight: bold;
}

#maincontent_mail {
    margin: 0 auto;
    width: 98%;
    #formTab {
        height: 40px;
        margin: 0;
        padding: 0 0 10px 0;
        ul {
            position: relative;
            list-style-type: none;
            padding: 0px 0 0 0px;
            margin: 0px 0 0 0px;
            max-width: 100%;
            li.mailform a {
                position: absolute;
                right: 50%;
                display: block;
                float: left;
                background-color: #FFA07A;
                height: 40px;
                width: 50%;
                padding: 15px 0 0 0;
                font-size: 0.8rem;
                color: #fff;
                text-align: center;
                font-weight: bold;
                line-height: 10px;
                text-decoration: none;
            }
            li.reserveform a {
                position: absolute;
                right: 0;
                display: block;
                float: left;
                background-color: #A52A2A;
                border-bottom: 3px solid #fff;
                height: 40px;
                width: 50%;

                padding: 10px 0 0 0;
                font-size: 0.8rem;
                color: #fff;
                text-align: center;
                font-weight: bold;
                line-height: 10px;
                text-decoration: none;
            }
        }
    }

    #formHead {
        border: 5px solid #FFA07A;
        border-bottom-width: 5px;
        border-bottom-style: solid;
        border-bottom-color: rgb(255, 160, 122);
        border-bottom: none;
        background: #FDF5E6;
        padding: 15px 25px;
        margin: 0;

    }
    #form #formWrap {
        padding: 20px 15px;
        border: 5px solid #FFA07A;
        border-top-width: 5px;
        border-top-style: solid;
        border-top-color: rgb(255, 160, 122);
        border-top: none;
        width: 100%;
        ul#mfp_phase_stat {
            display: table;
            margin: 5px auto;
        }
    }
}

#form #mailformpro {
    padding: 10px;
    dl.mailform {
        width: 100%;
        padding: 0px;
        text-align: right;
        border-top: solid 1px #CCC;
        margin: 0px;
        background: #fff;
        dt {
            float: none;
            width: 100%;
            text-align: left;
            background: #fff;
        }
        dd {
            float: none;
            width: 100%;
            padding: 10px 5px 10px;
            background: #fff;
        }
    }
}

#form div#mfp_phase_confirm {
    clear: both;
    width: 100%;
    margin: 10px auto;
}

#form table#mfp_confirm_table {
    border-spacing: 0px;
    border-collapse: collapse;
    width: 98%;
    th,
    td {
        width: 100%;
        display: block;
        font-size: 1.0rem;
        padding: 10px 10px 10px 50px;
    }
    th {
        background: #E8EEF9;
    }
    td {
        background: #fff;
    }
}


#maincontent_reserve {
    margin: 0 auto;
    width: 100%;
    #formTab {
        height: 40px;
        margin: 0;
        padding: 0 0 10px 0;
        ul {
            position: relative;
            list-style-type: none;
            padding: 0px 0 0 0px;
            margin: 0px 0 0 0px;
            max-width: 100%;
            li.mailform a {
                position: absolute;
                right: 50%;
                display: block;
                float: left;
                background-color: #FFA07A;
                border-bottom: 3px solid #fff;
                height: 40px;
                width: 50%;
                padding: 15px 0 0 0;
                font-size: 0.8rem;
                color: #fff;
                text-align: center;
                font-weight: bold;
                line-height: 10px;
                text-decoration: none;
            }
            li.reserveform a {
                position: absolute;
                right: 0;
                display: block;
                float: left;
                background-color: #A52A2A;
                border: 5px solid #A52A2A;
                height: 40px;
                width: 50%;

                padding: 10px 0 0 0;
                font-size: 0.8rem;
                color: #fff;
                text-align: center;
                font-weight: bold;
                line-height: 10px;
                text-decoration: none;
            }
        }
    }

    #formHead {
        border: 5px solid #A52A2A;
        border-bottom-width: 5px;
        border-bottom-style: solid;
        border-bottom-color: rgb(165, 42, 42);
        border-bottom: none;
        background: #FDF5E6;
        padding: 15px 25px;
        margin: 0;
    }

    #formWrap {
        padding: 20px 10px;
        border: 5px solid #A52A2A;
        border-top-width: 5px;
        border-top-style: solid;
        border-top-color: rgb(165, 42, 42);
        border-top: none;
        color: #666;
    }
}

.mfp_buttons button.button {
    padding: 8px;
}

.ssl {
    font-size: 0.8rem;
    text-align: center;
    img {
        margin-top: -10px;
    }
}

@media screen and (min-width:640px) {
    #maincontent_mail #form #mailformpro dl.mailform {
        width: 100%;
        clear: both;
        overflow: hidden;
        background: #fff;
        dt {
            float: left;
            width: 230px;
            text-align: left;
        }
        dd {
            float: right;
            width: 360px;
            padding: 10px 5px 10px;
        }
    }
    #maincontent_reserve #form #mailformpro dl.mailform {
        width: 100%;
        clear: both;
        overflow: hidden;
        background: #fff;
        dt {
            float: left;
            width: 180px;
            text-align: left;
        }
        dd {
            padding: 10px 5px 10px 220px;
        }
    }

}

@media screen and (min-width:768px) {
    #maincontent_mail {
        margin: 0 auto;
        width: 90%;
        position: relative;
        #formTab {
            height: 40px;
            margin: 0;
            padding: 0 0 10px 0;
            ul {
                position: relative;
                list-style-type: none;
                padding: 0px 0 0 0px;
                margin: 0px 0 0 0px;
                max-width: 100%;
                li.mailform a {
                    position: absolute;
                    right: 170px;
                    display: block;
                    float: left;
                    background-color: #FFA07A;
                    height: 40px;
                    width: 160px;
                    padding: 15px 0 0 0;
                    font-size: 0.85rem;
                    color: #fff;
                    text-align: center;
                    font-weight: bold;
                    line-height: 10px;
                    text-decoration: none;
                }
                li.reserveform a {
                    position: absolute;
                    right: 0;
                    display: block;
                    float: left;
                    background-color: #A52A2A;
                    border-bottom: 3px solid #fff;
                    height: 40px;
                    width: 160px;

                    padding: 10px 0 0 0;
                    font-size: 0.85rem;
                    color: #fff;
                    text-align: center;
                    font-weight: bold;
                    line-height: 10px;
                    text-decoration: none;
                }
            }
        }
    }
    #maincontent_reserve {
        margin: 0 auto;
        width: 90%;
        position: relative;
        #formTab {
            height: 40px;
            margin: 0;
            padding: 0 0 10px 0;
            ul {
                position: relative;
                list-style-type: none;
                padding: 0px 0 0 0px;
                margin: 0px 0 0 0px;
                max-width: 100%;
                li.mailform a {
                    position: absolute;
                    right: 170px;
                    display: block;
                    float: left;
                    background-color: #FFA07A;
                    border-bottom: 3px solid #fff;
                    height: 40px;
                    width: 160px;
                    padding: 15px 0 0 0;
                    font-size: 0.85rem;
                    color: #fff;
                    text-align: center;
                    font-weight: bold;
                    line-height: 10px;
                    text-decoration: none;
                }
                li.reserveform a {
                    position: absolute;
                    right: 0;
                    display: block;
                    float: left;
                    background-color: #A52A2A;
                    border: 5px solid #A52A2A;
                    height: 40px;
                    width: 160px;

                    padding: 10px 0 0 0;
                    font-size: 0.85rem;
                    color: #fff;
                    text-align: center;
                    font-weight: bold;
                    line-height: 10px;
                    text-decoration: none;
                }
            }
        }
    }
}

/**---------- メールマガジン ----------**/

#maincontent table.magazine {
    width: 264px;
    margin: 15px auto;
    border: solid 1px #ccc;
    background-color: #fff;
    text-align: left;
    border-collapse: collapse;
    th {
        background-color: #ffcc00;
        width: 264px;
        color: #ffffff;
        font-weight: bold;
        padding-top: 3px;
        padding-bottom: 2px;
        text-align: left;
        padding-left: 4px;
        padding-right: 0px;
    }
    td {
        width: 264px;
        background-color: #fff;
        padding: 5px;
    }
}

/**---------- ランディングページ ----------**/

#lp {
    #header {
        background-image: none;
        height: 180px;
        #title {
            font-size: 2.0rem;
            color: #222;
            text-align: center;
            padding-bottom: 15px;
            font-family: "Sawarabi Mincho", serif, Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
        }
        #catch {
            font-size: 1.1rem;
            color: red;
            text-align: center;
        }
    }
    #wrapper {
        background: #fff;
    }
}

#lp #maincontent {
    margin: 20px auto;
    width: 98%;
    article {
        padding: 5px;
    }
    ol.flow li {
        font-weight: bold;
    }
    .res_tel {
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        padding-bottom: 20px;
    }
}

#lp .clinic_info {
    margin: 10px auto;
    display: table;
}

#lp .off-canvas {
    width: 200px;
    ul {
        margin: 20px 10px 20px;
    }
    ul.menu li {
        margin-bottom: 10px;
    }
}

#lp .copyright {
    background: #2a1d10;
    text-align: center;
    height: 105px;
    padding: 10px 0 60px;
    color: #fff;
}

@media screen and (min-width:768px) {
    #lp #header {
        height: 230px;
        #title {
            font-size: 2.6rem;
        }
        #catch {
            font-size: 1.2rem;
        }
        #logo {
            line-height: 70px;
            height: 70px;
            width: 320px;
        }
        #tel_img {
            padding-top: 0;
            line-height: 70px;
            height: 70px;
        }
        ul#head_btn {
            list-style: none;
            position: absolute;
            top: 30px;
            li {
                float: left;
            }

        }
    }
    #lp #global-nav {
        li a {
            padding: 8px 20px;
            font-size: .9rem;
        }
    }
    #lp .copyright {
        height: 50px;
        padding: 10px 0 10px;

    }
}

@media screen and (min-width:960px) {
    #lp {
        #header {
            height: 250px;

            #logo {

                width: 360px;
            }

        }
    }
}
